import { render, staticRenderFns } from "./UniversalImage.vue?vue&type=template&id=01e144c8&scoped=true"
import script from "./UniversalImage.vue?vue&type=script&lang=ts"
export * from "./UniversalImage.vue?vue&type=script&lang=ts"
import style0 from "./UniversalImage.vue?vue&type=style&index=0&id=01e144c8&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01e144c8",
  null
  
)

export default component.exports