
import Vue from '@/vueTyped';
import { Photo } from '@/types';
import '@mux/videojs-kit/dist/index.css';
import debounce from 'lodash/debounce';

let importingVideojs: Promise<Function & any> | null = null;
async function importVideojs() {
    importingVideojs ??= import('@mux/videojs-kit').then(result => result.default as Function & any);
    return importingVideojs;
}

// This accepts a typical URL, a File (from an input), or a Photo (from the back end) as a `src`.
type AcceptableSrc = string | File | Photo;

const VIDEO_FORMATS = ['mov', 'mp4']; // TODO

export default Vue.extend({
    props: {
        src: {
            type: [String, File, Object as () => AcceptableSrc],
            required: true,
        },

        alt: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            height: 0,
            width: 0,
            debounceHandleScroll: null as any,
        };
    },

    computed: {
        type(): 'img' | 'video' | 'mux' {
            if (typeof this.src === 'string') {
                return VIDEO_FORMATS.some(format => (this.src as string).toLocaleLowerCase().includes(`.${format}`)) ? 'video' : 'img';
            } else if (this.src instanceof File) {
                return this.src.type.startsWith('video/') ? 'video' : 'img';
            } else if (this.src.additionalInfo?.mux) {
                return 'mux';
            } else if (this.src.secure_url) {
                return VIDEO_FORMATS.includes(this.src.format) ? 'video' : 'img';
            }
            throw new Error('UNSUPPORTED_FORMAT');
        },

        srcString(): string {
            if (typeof this.src === 'string') {
                return this.src;
            } else if (this.src instanceof File) {
                return URL.createObjectURL(this.src);
            } else if (this.type === 'mux') {
                return this.src.additionalInfo!.mux!.playback_id;
            } else if (this.src.secure_url) {
                if (VIDEO_FORMATS.includes(this.src.format)) {
                    return this.src.secure_url;
                } else {
                    if (this.width === 0 || this.height === 0) {
                        return '';
                    }
                    return this.$options.filters?.formatImage(this.src, this.width, this.height);
                }
            }
            return '';
        },
    },

    watch: {
        type: {
            immediate: true,
            async handler(type) {
                if (type === 'mux') {
                    await this.$nextTick();
                    await new Promise(resolve => setTimeout(resolve, 250)); // Work around an issue with <v-lazy>
                    const videojs = await importVideojs();
                    videojs(this.$refs.videoElement, {
                        fill: true,
                        responsive: true,
                    }, () => {
                        // Trigger "load" event handlers as if this were an <img>.
                        this.$el.dispatchEvent(new CustomEvent('load'));
                    });
                }
            },
        },

        srcString(srcString, oldSrcString) {
            this.cleanUpObjectURL(oldSrcString);
        },
    },

    created() {
        this.debounceHandleScroll = debounce(this.handleScroll, 200);
    },

    mounted() {
        addEventListener('scroll', this.debounceHandleScroll, true);
        this.checkSize();
    },

    destroyed() {
        removeEventListener('scroll', this.debounceHandleScroll, true);
        this.cleanUpObjectURL(this.srcString);
    },

    methods: {
        handleScroll(event: Event) {
            if (!(event.target instanceof Node) || !event.target.contains(this.$el)) return;
            if (this.type !== 'mux') return;
            const rect = this.$el.getBoundingClientRect();
            const center: [number, number] = [rect.left + rect.width / 2, rect.top + rect.height / 2];
            const elementAtCenter = this.$el.ownerDocument.elementFromPoint(...center);
            const isVisible = this.$el.contains(elementAtCenter);
            if (!isVisible) {
                importVideojs().then(videojs => {
                    const player = videojs.getPlayer(this.$refs.videoElement);
                    if (player && !player.paused()) {
                        player.pause();
                    }
                });
            }
        },

        checkSize() {
            this.height = this.$el?.clientHeight ?? this.height;
            this.width = this.$el?.clientWidth ?? this.width;
            if (this.width === 0 || this.height === 0) {
                console.warn('UniversalImage has no width and/or height', this.$el);
            }
        },

        async handleAutoPlay() {
            await new Promise(resolve => setTimeout(resolve, 50));
            (this.$el as HTMLVideoElement).pause();
        },

        cleanUpObjectURL(url: unknown) {
            if (typeof url === 'string' && url.startsWith('blob')) {
                URL.revokeObjectURL(url);
            }
        },
    },
});
